import { useContext } from "react";
import { List } from "@mui/material";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryItem } from "@/src/const/listingManagament";
import { useListingManagerPageInfo } from "@/src/hook";
import { BlaceV2Type } from "@/src/type";
import styles from "./MainSectionStepper.module.scss";
import { ItemStep } from "./components/ItemStep";

interface MainSectionStepperProps {
  categoriesList: CategoryItem[];
}

function MainSectionStepper({ categoriesList }: MainSectionStepperProps) {
  const { listingItemData } = useContext(ListingManagementContext) || {};
  const { searchDataType } = useListingManagerPageInfo();

  const currentDataType =
    listingItemData?.dataType ?? searchDataType ?? BlaceV2Type.SearchDataTypes.Venue;

  if (!!listingItemData && listingItemData.status !== BlaceV2Type.ListingStatus.DRAFT) {
    return false;
  }

  return (
    <List className={styles.stepperWrapper}>
      {categoriesList.map(
        ({ id, category, completed, dataTypes }, index) =>
          dataTypes.includes(currentDataType) && (
            <ItemStep id={index + 1} category={category} completed={completed} key={id} />
          ),
      )}
    </List>
  );
}

export default MainSectionStepper;
