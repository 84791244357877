import { useState } from "react";
import { KEYS, SharedConfigManager } from "blace-frontend-library";
import { RootRouter } from "./component/router";
import { ENV } from "./const";
import { createCache, ThemeCustomization } from "./styles";

function setConfig() {
  //init the config for the frontend code library
  SharedConfigManager.init({
    [KEYS.ASSET_URL]: ENV.ASSET_URL,
    [KEYS.TEXT_CDN_FRONTEND]: ENV.FRONTEND_CDN_URL + "/frontend",
    [KEYS.TEXT_CDN_URL]: ENV.FRONTEND_CDN_URL,
    [KEYS.DATADOG_TOKEN]: "DATADOG_TOKEN",
    [KEYS.ENV]: ENV.ENV,
    [KEYS.DEBUG_DEV_MODE]: ENV.DEBUG_MODE,
    [KEYS.APP_NAME]: "blace-b2b-frontend",
    [KEYS.BLACE_V2_API]: ENV.BLACE_V2_API,
    [KEYS.APP_API]: ENV.APP_API,
    [KEYS.APP_TENANT]: ENV.APP_TENANT,
    [KEYS.STRAPI_API_URL]: ENV.STRAPI_API_URL,
    [KEYS.STRAPI_API_TOKEN]: ENV.STRAPI_API_TOKEN,
    [KEYS.GOOGLE_MAPS_KEY]: ENV.GOOGLE_MAPS_KEY,
  });
  return true;
}

function App() {
  const cache = createCache();
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const [_configReady, _setConfigReady] = useState<boolean>(setConfig());

  return (
    <ThemeCustomization emotionCache={cache}>
      <RootRouter />
    </ThemeCustomization>
  );
}

export default App;
