import {
  FormEvent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import {
  BaseIcon,
  BaseLoader,
  BlaceV2Type as BlaceV2TypeClient,
  DEFAULT_LOCATION,
  FormHelper,
  NumberHelper,
} from "blace-frontend-library";
import cn from "classnames";
import { useBlocker } from "react-router-dom";
import { TextareaDescription } from "@/src/component/base/TextareaDescription";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { FileUploadSection } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/FileUploadSection";
import { SaveButton } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/SaveButton";
import { NUMBER_FIELD_REG_EXP, UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { useDetailsForm, useListingManagerPageInfo } from "@/src/hook";
import { FormLogic } from "@/src/model";
import { GoogleServiceV2 } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { BlockerArgs, FormRef } from "@/src/type/app";
import { AddressHelper } from "@/src/util";
import styles from "./DetailsForm.module.scss";

export type MapLocationMarker = {
  lat: number;
  lng: number;
  to?: string;
};

interface DetailsFormProps {
  onAddressChange: (data: MapLocationMarker | null) => void;
  onSaveDetailsFormData?: () => void;
}

export enum InputList {
  ListingName = "title",
  ListingDescription = "description",
  ListingThingsYouShouldKnow = "thingsYouShouldKnow",
  ListingLocation = "locations",
  FloorsNumber = "numberOfFloors",
  SquareFootage = "sqFootage",
  CeilingHeight = "ceilingHeight",
  StandingCapacity = "standing",
  SeatedCapacity = "seated",
  TheaterCapacity = "theater",
  Files = "files",
  Regions = "regions",
}

function DetailsForm(props: DetailsFormProps, ref: React.Ref<FormRef>) {
  const { onAddressChange, onSaveDetailsFormData } = props;
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [areFilesBlocked, setAreFilesBlocked] = useState(false);

  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();

  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null);

  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  const listingManagementContext = useContext(ListingManagementContext);
  const { searchDataType } = useListingManagerPageInfo();
  const currentDataType = listingManagementContext?.listingItemData?.dataType ?? searchDataType;
  const isVenue = currentDataType === BlaceV2Type.SearchDataTypes.Venue;
  const isVendor = currentDataType === BlaceV2Type.SearchDataTypes.Vendor;

  const { formik } = useDetailsForm({
    listingItemSaveHandler: listingManagementContext?.listingItemSaveHandler,
    listingItemData: listingManagementContext?.listingItemData,
    dataType: currentDataType,
  });
  const map = useMap();
  const places = useMapsLibrary("places");
  const geocodingLib = useMapsLibrary("geocoding");

  const isLocationError = FormHelper.formikCheckError(formik, InputList.ListingLocation);

  const [manualAddress, setManualAddress] = useState<string>(
    formik.values[InputList.ListingLocation]?.formattedAddress ?? "",
  );

  const [address, setAddress] = useState<BlaceV2Type.SearchType.SearchLocation | null>(
    formik.values[InputList.ListingLocation] ?? null,
  );

  const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return;
      setIsLoadingAddress(true);

      const detailRequestOptions = {
        placeId,
        fields: ["geometry", "name", "formatted_address"],
        sessionToken,
      };

      const detailsRequestCallback = async (
        placeDetails: google.maps.places.PlaceResult | null,
      ) => {
        const geocodedResponse = await geocoder?.geocode({ placeId });
        if (!geocodedResponse) {
          setIsLoadingAddress(false);
          return;
        }

        const geocodedAddress = geocodedResponse.results[0];
        const reversedGeoInfo = await GoogleServiceV2.getGoogleReverseGeoCode(
          geocodedAddress.geometry.location.lat(),
          geocodedAddress.geometry.location.lng(),
        );
        const searchLocation = AddressHelper.googleAddressToSearchLocation(
          geocodedAddress,
          reversedGeoInfo.body?.payload,
        );
        setAddress(searchLocation);
        setPredictionResults([]);
        setManualAddress(placeDetails?.formatted_address ?? "");
        setSessionToken(new places.AutocompleteSessionToken());
        formik.setFieldValue(InputList.ListingLocation, searchLocation ?? null);
        setIsLoadingAddress(false);
      };

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    },
    [places, placesService, sessionToken, geocoder, formik],
  );

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([]);
        return;
      }
      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);
      setPredictionResults(response.predictions);
    },
    [autocompleteService, sessionToken],
  );

  const onManualAddressChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value;

      setManualAddress(value);
      setAddress(null);
      fetchPredictions(value);
    },
    [fetchPredictions],
  );

  const handleManualAddressOnBlur = (): void => {
    if (!address) {
      setManualAddress("");
    }
  };

  const handleSetFiles = (files: BlaceV2Type.SearchType.SearchFile[]): void => {
    formik.setFieldValue(InputList.Files, files ?? []);
  };

  const handleRegionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    region: string,
  ) => {
    const currentRegions = formik.values[InputList.Regions];

    const newRegions = e.target.checked
      ? [...currentRegions, region]
      : currentRegions.filter((currentRegion: string) => currentRegion !== region);

    const allowedRegions: string[] = [BlaceV2TypeClient.Regions.NY, BlaceV2TypeClient.Regions.LA];
    const uniqueRegions: string[] = Array.from(new Set(
      newRegions.filter((currentRegion: string) => allowedRegions.includes(currentRegion))
    ));

    formik.setFieldValue(InputList.Regions, uniqueRegions);
    updateMapForVendor(uniqueRegions);
  };

  const updateMapForVendor = (regions?: string[]) => {
    if (regions?.includes(BlaceV2TypeClient.Regions.LA) && regions?.length === 1) {
      onAddressChange(DEFAULT_LOCATION.LA);
    } else {
      onAddressChange(DEFAULT_LOCATION.NY);
    }
  };

  // clear the address on empty input
  useEffect(() => {
    if (!manualAddress) {
      setAddress(null);
    }
  }, [manualAddress, setAddress]);

  // update the marker on map with current address
  useEffect(() => {
    if (address) {
      const addressCoordinates: MapLocationMarker = {
        lat: address.latitude,
        lng: address.longitude,
      };

      onAddressChange(addressCoordinates);
    } else {
      onAddressChange(null);
    }
  }, [address, onAddressChange]);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [map, places]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: BlockerArgs) =>
      !!listingManagementContext?.hasUnsavedData &&
      currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // prevent data loss
  useEffect(() => {
    listingManagementContext?.setHasUnsavedData(formik.dirty);

    if (formik.dirty) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [formik.dirty, listingManagementContext]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      listingManagementContext?.setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the save button status
  useEffect(() => {
    const isDisabled =
      !formik.isValid ||
      !formik.dirty ||
      formik.isSubmitting ||
      isLoadingAddress ||
      areFilesBlocked;
    listingManagementContext?.setIsSaveButtonDisabled(isDisabled);
  }, [
    formik.dirty,
    formik.isSubmitting,
    formik.isValid,
    listingManagementContext,
    isLoadingAddress,
    areFilesBlocked,
  ]);

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));

  return (
    <div className={styles.detailsForm}>
      <form onSubmit={formik.handleSubmit} data-testid="details-form">
        <div className={styles.formItem}>
          <label htmlFor={InputList.ListingName} className={cn(styles.inputLabel, styles.required)}>
            Listing Name
          </label>
          <TextField
            className={styles.textField}
            placeholder="Add title of your Listing"
            fullWidth
            required
            disabled={listingManagementContext?.isEditRequestSubmitting}
            value={formik.values[InputList.ListingName]}
            onChange={(e) => {
              formik.setFieldValue(InputList.ListingName, e?.target?.value ?? "");
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.ListingName)}
            error={FormHelper.formikCheckError(formik, InputList.ListingName)}
            id={InputList.ListingName}
          />
        </div>
        <div className={styles.formItem}>
          <label
            htmlFor={InputList.ListingDescription}
            className={cn(styles.inputLabel, styles.required)}
          >
            Listing Description
          </label>
          <TextField
            className={cn(styles.textField, styles.listingDescription)}
            placeholder="Add full description of your Listing"
            fullWidth
            required
            disabled={listingManagementContext?.isEditRequestSubmitting}
            value={formik.values[InputList.ListingDescription]}
            onChange={(e) => {
              formik.setFieldValue(InputList.ListingDescription, e?.target?.value ?? "");
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.ListingDescription)}
            error={FormHelper.formikCheckError(formik, InputList.ListingDescription)}
            multiline
            rows={4}
            id={InputList.ListingDescription}
          />
          <TextareaDescription
            value={formik.values[InputList.ListingDescription]}
            isError={FormHelper.formikCheckError(formik, InputList.ListingDescription)}
            maxChars={7999}
            minChars={50}
          />
        </div>
        {isVenue && (
          <div className={styles.formItem}>
            <label
              htmlFor={InputList.ListingLocation}
              className={cn(styles.inputLabel, styles.required)}
            >
              Location
            </label>
            <div className={styles.textFieldAddressWrapper}>
              <BaseIcon
                iconFileName="pinIcon"
                iconAlt="pin icon"
                iconSize={20}
                className={styles.addressIcon}
              />
              {isLoadingAddress && (
                <BaseLoader size={12} wrapperClassName={styles.addressInputLoaderWrapper} />
              )}
              <input
                placeholder="Enter Listing address"
                onBlur={handleManualAddressOnBlur}
                onChange={onManualAddressChange}
                value={manualAddress}
                className={cn(styles.textFieldAddress, {
                  [styles.invalid]: isLocationError,
                })}
                id={InputList.ListingLocation}
              />
              {isLocationError && (
                <FormHelperText error>
                  {FormHelper.formikErrorMessage(formik, InputList.ListingLocation)}
                </FormHelperText>
              )}
              {predictionResults.length > 0 && (
                <List component="nav" aria-labelledby="nested-list-subheader">
                  {predictionResults.map(({ place_id, description }) => (
                    <ListItemButton key={place_id} onClick={() => handleSuggestionClick(place_id)}>
                      <ListItemText primary={description} />
                    </ListItemButton>
                  ))}
                </List>
              )}
            </div>
          </div>
        )}
        {isVenue && (
          <div className={styles.formSection}>
            <div className={styles.formItemsWrapper}>
              <div className={styles.formItem}>
                <label htmlFor={InputList.FloorsNumber} className={cn(styles.inputLabel)}>
                  Number of Floors
                </label>
                <TextField
                  disabled={listingManagementContext?.isEditRequestSubmitting}
                  value={NumberHelper.formatFormInputValue(
                    formik.values[InputList.FloorsNumber] || "",
                    true,
                  )}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
                      const clearedValue = NumberHelper.clearInputValue(value);
                      formik.setFieldValue(InputList.FloorsNumber, clearedValue ?? "");
                    }
                  }}
                  helperText={FormHelper.formikErrorMessage(formik, InputList.FloorsNumber)}
                  error={FormHelper.formikCheckError(formik, InputList.FloorsNumber)}
                  fullWidth
                  className={styles.textField}
                  id={InputList.FloorsNumber}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor={InputList.SquareFootage} className={cn(styles.inputLabel)}>
                  Square Footage
                </label>
                <TextField
                  disabled={listingManagementContext?.isEditRequestSubmitting}
                  value={NumberHelper.formatFormInputValue(
                    formik.values[InputList.SquareFootage] || "",
                    true,
                  )}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
                      const clearedValue = NumberHelper.clearInputValue(value);
                      formik.setFieldValue(InputList.SquareFootage, clearedValue ?? "");
                    }
                  }}
                  helperText={FormHelper.formikErrorMessage(formik, InputList.SquareFootage)}
                  error={FormHelper.formikCheckError(formik, InputList.SquareFootage)}
                  fullWidth
                  className={styles.textField}
                  id={InputList.SquareFootage}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor={InputList.CeilingHeight} className={cn(styles.inputLabel)}>
                  Ceiling height
                </label>
                <TextField
                  disabled={listingManagementContext?.isEditRequestSubmitting}
                  value={NumberHelper.formatFormInputValue(
                    formik.values[InputList.CeilingHeight] || "",
                    true,
                  )}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
                      const clearedValue = NumberHelper.clearInputValue(value);
                      formik.setFieldValue(InputList.CeilingHeight, clearedValue ?? "");
                    }
                  }}
                  error={FormHelper.formikCheckError(formik, InputList.CeilingHeight)}
                  helperText={FormHelper.formikErrorMessage(formik, InputList.CeilingHeight)}
                  fullWidth
                  className={styles.textField}
                  id={InputList.CeilingHeight}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {isVendor && (
          <div className={styles.formItem}>
            <label htmlFor={InputList.Regions} className={cn(styles.inputLabel, styles.required)}>
              City
            </label>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values[InputList.Regions].includes(
                      BlaceV2TypeClient.Regions.NY,
                    )}
                    className={styles.checkbox}
                    onChange={(e) =>
                      handleRegionChange(e, BlaceV2TypeClient.Regions.NY)
                    }
                    inputProps={{ "aria-label": BlaceV2TypeClient.RegionDisplayValues.NY }}
                    defaultChecked
                  />
                }
                label={BlaceV2TypeClient.RegionDisplayValues.NY}
                classes={{ label: styles.checkboxLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values[InputList.Regions].includes(
                      BlaceV2TypeClient.Regions.LA
                    )}
                    className={styles.checkbox}
                    onChange={(e) =>
                      handleRegionChange(e, BlaceV2TypeClient.Regions.LA)
                    }
                    inputProps={{ "aria-label": BlaceV2TypeClient.RegionDisplayValues.LA }}
                  />
                }
                label={BlaceV2TypeClient.RegionDisplayValues.LA}
                classes={{ label: styles.checkboxLabel }}
              />
            </FormGroup>
          </div>
        )}
        <FileUploadSection
          listingItemFiles={formik.values[InputList.Files]}
          setListingItemFiles={handleSetFiles}
          setAreFilesBlocked={setAreFilesBlocked}
        />
        {isVenue && (
          <div className={styles.formSection}>
            <Typography className={styles.formSectionTitle}>Guest Counts</Typography>
            <div className={styles.formItemsWrapper}>
              <div className={styles.formItem}>
                <label
                  htmlFor={InputList.StandingCapacity}
                  className={cn(styles.inputLabel, styles.required)}
                >
                  Standing Capacity
                </label>
                <TextField
                  disabled={listingManagementContext?.isEditRequestSubmitting}
                  value={NumberHelper.formatFormInputValue(
                    formik.values[InputList.StandingCapacity] || "",
                    true,
                  )}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
                      const clearedValue = NumberHelper.clearInputValue(value);
                      formik.setFieldValue(InputList.StandingCapacity, clearedValue ?? "");
                    }
                  }}
                  helperText={FormHelper.formikErrorMessage(formik, InputList.StandingCapacity)}
                  error={FormHelper.formikCheckError(formik, InputList.StandingCapacity)}
                  fullWidth
                  className={styles.textField}
                  required
                  id={InputList.StandingCapacity}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor={InputList.SeatedCapacity} className={styles.inputLabel}>
                  Seated Capacity
                </label>
                <TextField
                  disabled={listingManagementContext?.isEditRequestSubmitting}
                  value={NumberHelper.formatFormInputValue(
                    formik.values[InputList.SeatedCapacity] || "",
                    true,
                  )}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
                      const clearedValue = NumberHelper.clearInputValue(value);
                      formik.setFieldValue(InputList.SeatedCapacity, clearedValue ?? "");
                    }
                  }}
                  helperText={FormHelper.formikErrorMessage(formik, InputList.SeatedCapacity)}
                  error={FormHelper.formikCheckError(formik, InputList.SeatedCapacity)}
                  fullWidth
                  className={styles.textField}
                  id={InputList.SeatedCapacity}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </div>
              <div className={styles.formItem}>
                <label htmlFor={InputList.TheaterCapacity} className={styles.inputLabel}>
                  Theater Capacity
                </label>
                <TextField
                  disabled={listingManagementContext?.isEditRequestSubmitting}
                  value={NumberHelper.formatFormInputValue(
                    formik.values[InputList.TheaterCapacity] || "",
                    true,
                  )}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
                      const clearedValue = NumberHelper.clearInputValue(value);
                      formik.setFieldValue(InputList.TheaterCapacity, clearedValue ?? "");
                    }
                  }}
                  helperText={FormHelper.formikErrorMessage(formik, InputList.TheaterCapacity)}
                  error={FormHelper.formikCheckError(formik, InputList.TheaterCapacity)}
                  fullWidth
                  className={styles.textField}
                  id={InputList.TheaterCapacity}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {isVenue && (
          <div className={styles.formItem}>
            <label htmlFor={InputList.ListingThingsYouShouldKnow} className={styles.inputLabel}>
              Things you should know
            </label>
            <TextField
              className={cn(styles.textField, styles.thingsYouShouldKnow)}
              placeholder="Add useful information about your Listing"
              fullWidth
              disabled={listingManagementContext?.isEditRequestSubmitting}
              value={formik.values[InputList.ListingThingsYouShouldKnow]}
              onChange={(e) => {
                formik.setFieldValue(InputList.ListingThingsYouShouldKnow, e?.target?.value ?? "");
              }}
              helperText={FormHelper.formikErrorMessage(
                formik,
                InputList.ListingThingsYouShouldKnow,
              )}
              error={FormHelper.formikCheckError(formik, InputList.ListingThingsYouShouldKnow)}
              multiline
              rows={4}
              id={InputList.ListingThingsYouShouldKnow}
            />
            <TextareaDescription
              minChars={5}
              value={formik.values[InputList.ListingThingsYouShouldKnow]}
              isError={FormHelper.formikCheckError(formik, InputList.ListingThingsYouShouldKnow)}
            />
          </div>
        )}
      </form>
      <SaveButton
        isSaveButtonLoading={Boolean(listingManagementContext?.isEditRequestSubmitting)}
        isSaveButtonDisabled={Boolean(listingManagementContext?.isSaveButtonDisabled)}
        onSaveFormData={onSaveDetailsFormData}
      />
    </div>
  );
}

export default forwardRef(DetailsForm);
