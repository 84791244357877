import { createContext } from "react";
import type { Dispatch, SetStateAction } from "react";
import { ListingCategories } from "@/src/const/listingManagament";
import { ListingItemEditableData } from "./ListingManagement";

export interface ListingManagementContextType {
  requestSearchId?: string;
  listingItemData?: ListingItemEditableData;
  listingItemMainImageUrl?: string;
  setListingItemMainImageUrl: Dispatch<SetStateAction<string>>;
  categorySelected?: ListingCategories;
  categorySelectionHandler: (category: ListingCategories) => void;
  setListingItemWarningText: (infoText: string) => void;
  hasUnsavedData: boolean;
  setHasUnsavedData: Dispatch<SetStateAction<boolean>>;
  isSaveButtonDisabled: boolean;
  setIsSaveButtonDisabled: Dispatch<SetStateAction<boolean>>;
  isEditRequestSubmitting: boolean;
  listingItemSaveHandler: (
    formValuesData: ListingItemEditableData,
    showToastMessage?: boolean,
    successToastMessage?: string,
  ) => Promise<Record<string, string>>;
}

export const ListingManagementContext =
  createContext<ListingManagementContextType | undefined>(undefined);
