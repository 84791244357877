import { useMemo } from "react";
import { ListItem, Typography } from "@mui/material";
import { BaseIcon, StringHelper } from "blace-frontend-library";
import cn from "classnames";
import { CategoryItem, ListingCategories } from "@/src/const/listingManagament";
import styles from "./ItemStep.module.scss";

function ItemStep({ id, category, completed }: Omit<CategoryItem, "dataTypes">) {
  const itemCompeletedClass = useMemo(
    () => ({
      [styles.completed]: completed,
    }),
    [completed]
  );
  const iconName = useMemo(() => {
    const categoryCapitalized = StringHelper.capitalize(category);
    return completed
      ? `${categoryCapitalized}IconCompleted`
      : `${categoryCapitalized}Icon`;
  }, [category, completed]);
  // todo: remove it after full list of contacts implementation
  const categoryTitle = useMemo(() => {
    if (category === ListingCategories.Contacts) {
      return "contact";
    }
    return category;
  }, [category]);

  return (
    <ListItem
      className={cn(styles.categoryItem, itemCompeletedClass)}
      data-testid={`item-step-${category}`}
    >
      <div className={cn(styles.iconWrapper, itemCompeletedClass)}>
        <BaseIcon iconFileName={iconName} iconAlt={category} iconSize={20} />
      </div>
      <div className={styles.stepTextWrapper}>
        <Typography
          className={cn(styles.stepNumber, itemCompeletedClass)}
        >{`Step ${id}`}</Typography>
        <Typography className={cn(styles.stepCategory, itemCompeletedClass)}>
          {categoryTitle}
        </Typography>
      </div>
    </ListItem>
  );
}

export default ItemStep;
