import React, { ReactNode, useContext } from "react";
import { BaseButton, BaseIcon } from "blace-frontend-library";
import { useSearchParams } from "react-router-dom";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { SELECTED_CATEGORY_PARAM } from "@/src/const/listingManagament";
import styles from "./MobileMainSection.module.scss";

interface MobileMainSectionProps {
  children: ReactNode;
}

function MobileMainSection({ children }: MobileMainSectionProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const listingManagementContext = useContext(ListingManagementContext);

  const handleGoBack = () => {
    // make sure that we don't loss the changes
    if (listingManagementContext?.hasUnsavedData
        && window
        && !window.confirm(UNSAVED_CHANGES_WARNING_TEXT)
    ) {
      return;
    }

    searchParams.delete(SELECTED_CATEGORY_PARAM);
    setSearchParams(searchParams);
  };

  return (
    <div data-testid="main-section-mobile">
      {Boolean(listingManagementContext?.categorySelected) && (
        <div>
          <BaseButton className={styles.backButton} onClick={handleGoBack}>
            <BaseIcon
              iconFileName="arrowLeftIcon"
              iconAlt="go back event icon"
              iconSize={20}
              className={styles.backButtonIcon}
            />
            Back to categories. {listingManagementContext?.listingItemData?.title}
          </BaseButton>
        </div>
      )}
      {children}
    </div>
  );
}

export default MobileMainSection;
