import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  ActivityLogic,
  FAQCategory,
  Log,
  StrapiCMSAPI,
} from "blace-frontend-library";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import { BusinessFAQType } from "@/src/type/strapiCMS";
import styles from "./FAQContainer.module.scss";

function FAQContainerComponent() {
  const [FAQsData, setFAQsData] = useState<[]>([]);
  const dashboardContext = useContext(DashboardContext);

  useEffect(() => {
    (async () => {
      try {
        const dataResponse =
          await StrapiCMSAPI.getBusinessFAQ();

        dataResponse.response.data.sort(
          (a: BusinessFAQType, b: BusinessFAQType) => (a.attributes?.rank ?? 0) - (b.attributes?.rank ?? 0)
        );

        setFAQsData(dataResponse.response.data);
      } catch (error) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "FAQContainerComponent.tsx",
          "FetchFAQError",
          [error]
        );
      }
    })();
  }, []);

  if (!FAQsData.length) {
    return (
      <div className={styles.loaderWrapper}>
        <CircularProgress size={40} color="secondary" data-testid="progress"/>
      </div>
    );
  }

  const handleOpenQuestion = (question?: string, category?: string) => {
    ActivityLogic.toActivityService({
      action: "faqQuestionSelect",
      actionId: question || "",
      actionIdType: "faq",
      actionMessage: question || "",
      data1Type: "inquiry",
      data1Value: dashboardContext.selectedInquiry?.inquiryId || "",
      data2Type: "faqCategory",
      data2Value: category || "",
      locationInApp: "FAQQuestion.tsx",
    });
  };

  return (
    <Box className={styles.FAQContainerWrapper}>
      <Typography className={styles.FAQContainerTitle}>FAQs</Typography>
      <ul className={styles.categoriesList} data-testid="FAQSection">
        {FAQsData.map((category: BusinessFAQType) => {
          return (
            <li key={category.id} className={styles.categoryItemWrapper}>
              <FAQCategory categoryData={category.attributes} onQuestionOpen={handleOpenQuestion} />
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

export default FAQContainerComponent;
