import { v4 } from "uuid";

export function uniqueId(
  withoutDashes: boolean = true
) {
  const id = `${v4()}`;
  if (withoutDashes) {
    const split = id.split("-");
    let newId = "";
    for (const sequence of split) {
      newId += sequence;
    }
    return newId;
  }
  return id;
}
