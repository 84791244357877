import { ChangeEvent, useContext } from "react";
import { List, ListItem, Radio } from "@mui/material";
import { BaseIcon } from "blace-frontend-library";
import cn from "classnames";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryItem, ListingCategories } from "@/src/const/listingManagament";
import { useListingManagerPageInfo } from "@/src/hook";
import { BlaceV2Type } from "@/src/type";
import styles from "./CategoriesList.module.scss";

interface CategoriesListProps {
  categoriesList: CategoryItem[];
}

function CategoriesList({ categoriesList }: CategoriesListProps) {
  const { categorySelectionHandler, listingItemData, categorySelected } =
    useContext(ListingManagementContext) || {};
  const { searchDataType } = useListingManagerPageInfo();

  const currentDataType =
    listingItemData?.dataType ?? searchDataType ?? BlaceV2Type.SearchDataTypes.Venue;

  // todo: remove it after full list of contacts implementation
  const renderCategoryTitle = (category: ListingCategories): string => {
    if (category === ListingCategories.Contacts) {
      return "contact";
    }
    return category;
  };

  const categoryHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    categorySelectionHandler?.(event?.target?.value as ListingCategories);
  };

  return (
    <List className={styles.categoriesList} data-testid="categories-list">
      {categoriesList.map(
        ({ id, category, completed, dataTypes }) =>
          dataTypes.includes(currentDataType) && (
            <ListItem
              key={id}
              className={cn(styles.categoriesListItem, {
                [styles.isActive]: category === categorySelected,
              })}
            >
              <label htmlFor={`category-${id}`} className={styles.categoryLabel}>
                {renderCategoryTitle(category)}
                <Radio
                  id={`category-${id}`}
                  checked={category === categorySelected}
                  onChange={categoryHandleChange}
                  value={category}
                  color="default"
                  name="category"
                  inputProps={{ "aria-label": category }}
                  size="small"
                  className={styles.categoryRadioButton}
                />
                {completed ? (
                  <BaseIcon
                    iconFileName="categorySelectedIcon"
                    iconAlt="category selected"
                    iconSize={18}
                  />
                ) : (
                  <div className={styles.fakeRadioButton}></div>
                )}
              </label>
            </ListItem>
          ),
      )}
    </List>
  );
}

export default CategoriesList;
