import { SyntheticEvent, useContext, useMemo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";
import { DASHBOARD_FILTER_PARAM } from "@/src/component/view/Dashboard/DashboardConstant";
import { DashboardContext } from "@/src/component/view/Dashboard/DashboardContext";
import styles from "./SearchItemFilter.module.scss";

type OptionType = {
  label: string;
  value: string;
  id: string;
}

const DEFAULT_OPTION: OptionType = { label: "", value: "", id: "empty" };

const FILTER_OBJECT_STATE = `${DASHBOARD_FILTER_PARAM.SEARCH_ITEM}ObjectState`;

interface SearchItemFilterProps {
  preFilterState: any;
  setPreFilterState: (state: any) => void;
}

function SearchItemFilter({
  preFilterState,
  setPreFilterState
}: SearchItemFilterProps) {
  const dashboardContext = useContext(DashboardContext);

  /**
   * when the user selects an option propogate the change to the pre filter state
   * @param _event - not used
   * @param value - the value choosen by the user
   */
  const onChangeHandler = (
    _event: SyntheticEvent<Element, Event>,
    value: OptionType | null
  ) => {
    setPreFilterState({
      ...preFilterState,
      ...{
        //this is an area to prepare for using this component for multi-select someday
        [DASHBOARD_FILTER_PARAM.SEARCH_ITEM]: value?.id ? [value?.id] : "",
        [FILTER_OBJECT_STATE]: value
      }
    });
  };

  /**
   * when no option is selected check the url params
   */
  const selectDefaultOption = (options: OptionType[]): OptionType => {
    if (Array.isArray(preFilterState?.[DASHBOARD_FILTER_PARAM.SEARCH_ITEM])) {
      const selectedOption = (options ?? [])?.find(
        opt => opt.id === preFilterState?.[DASHBOARD_FILTER_PARAM.SEARCH_ITEM]?.[0]
      );
      return selectedOption ?? DEFAULT_OPTION;
    }
    return DEFAULT_OPTION;
  };

  if ((dashboardContext?.searchCatalogItemFilters ?? []).length === 0) {
    return <></>;
  }

  /**
   * cache all the options for this drop down
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const options: OptionType[] = useMemo(() => (
    [
      ...[DEFAULT_OPTION],
      ...(dashboardContext?.searchCatalogItemFilters ?? []).map(f => {
        return {
          label: f.title, value: f.searchId, id: f.searchId
        };
      })
    ]
  ), [dashboardContext?.searchCatalogItemFilters]);

  return (
    <Autocomplete<OptionType>
      disablePortal
      id="searchItemFilter"
      options={options}
      value={preFilterState[FILTER_OBJECT_STATE] ?? selectDefaultOption(options)}
      onChange={onChangeHandler}
      className={styles.select}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            key={option.id}
            style={{
              display: option.id === "empty" ? "none" : undefined
            }}
          >
            {option.label}
          </li>
        );
      }}
      renderInput={params => (
        <TextField {...params} className={styles.customInput} label="Choose an venue or vendor" />
      )}
      popupIcon={<KeyboardArrowDownIcon />}
    />
  );
}

export default SearchItemFilter;
