import React, { PropsWithChildren, useMemo } from "react";
import HeadingSectionContent
  from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/HeadingSectionContent/HeadingSectionContent";
import { ListingCategories } from "@/src/const/listingManagament";
import styles from "./CategoryContentWrapper.module.scss";

interface CategoryContentWrapperProps extends PropsWithChildren {
  category: ListingCategories;
  onSaveFormData?: () => void;
}

function CategoryContentWrapper({
  category,
  onSaveFormData,
  children,
}: CategoryContentWrapperProps) {
  // todo: remove it after full list of contacts implementation
  const categoryTitle = useMemo(() => {
    if (category === ListingCategories.Contacts) {
      return "contact";
    }
    return category;
  }, [category]);

  return (
    <div className={styles.categoryWrapper} data-testid={`${category}-content`}>
      <HeadingSectionContent onSaveFormData={onSaveFormData} title={categoryTitle} />
      <div className={styles.contentSection}>{children}</div>
    </div>
  );
}

export default CategoryContentWrapper;
