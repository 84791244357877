import { useBreakPointDown } from "blace-frontend-library";
import { CategoryItem } from "@/src/const/listingManagament";
import styles from "./MainSection.module.scss";
import { MainSectionContent } from "./components/MainSectionContent";
import { MainSectionStepper } from "./components/MainSectionStepper";
import { MobileMainSection } from "./components/MobileMainSection";

interface MainSectionProps {
  categoriesList: CategoryItem[];
}

function MainSection({
  categoriesList,
}: MainSectionProps) {
  const isMobile = useBreakPointDown("md");

  if (isMobile) {
    return (
      <MobileMainSection>
        <MainSectionContent />
      </MobileMainSection>
    );
  }

  return (
    <div className={styles.mainSection} data-testid="desktop-main-section">
      <MainSectionStepper categoriesList={categoriesList} />
      <MainSectionContent />
    </div>
  );
}

export default MainSection;
