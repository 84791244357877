import { createContext } from "react";
import { BlaceB2BType, BlaceV1Type, BlaceV2Type } from "@/src/type";

export interface DashboardContextType {
  selectedInquiry?: BlaceV2Type.InquirySearchType | undefined;
  searchItemImageUrl?: string;
  clientInitials?: string;
  searchCatalogItemFilters?: BlaceB2BType.B2BSearchType.B2BSearchAccount[];
  refreshLogId: string;
  needsUpdateInquiryNumber?: number;
  loadingProcess?: string;
  refreshLog?: ()=> void;
  closeInquiry?: (closeReason: BlaceB2BType.B2BInquiryType.CloseInquiryOptions) => void; 
  reopenInquiry?: (reopenReason: string) => void;
  lastSearchId?: string;
  currentUser?: BlaceV1Type.AuthType.LoginResponseProfile;
}

export const DashboardContext = createContext<DashboardContextType>({
  refreshLogId: "init"
});
