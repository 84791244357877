import React, { useContext } from "react";
import { TagSection } from "blace-frontend-library";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { ListingTag } from "@/src/type/app";
import { SearchAttribute } from "@/src/type/blaceV2/search/SearchType";
import styles from "./TagsSectionItem.module.scss";

interface TagSectionProps {
  sectionTitle: string;
  sectionDataType: string;
  tags: ListingTag[];
  selectedTags: SearchAttribute[];
  setSelectedTags: React.Dispatch<React.SetStateAction<SearchAttribute[]>>;
}

const TagsSectionItem = ({
  sectionTitle,
  sectionDataType,
  tags,
  selectedTags,
  setSelectedTags,
}: TagSectionProps) => {
  const { hasUnsavedData, setHasUnsavedData } = useContext(ListingManagementContext) || {};

  // we had a bug, which leads to attribute duplicates, so we need to make the result list unique
  const selectedSectionTags = selectedTags.filter(
    (selectedTag) =>
      selectedTag.attributeType === sectionDataType &&
      !!tags.find((tag) => tag.value === selectedTag.attributeId),
  );
  const uniqueSelectedSectionTags = Array.from(
    selectedSectionTags.reduce(
      (tagsMap, tag) => tagsMap.set(
        tag.attributeType + tag.attributeId, // the unique identifier of the tag
        tag
      ),
      new Map<string, typeof selectedSectionTags[number]>()
    ).values()
  );
  const selectedTagsNumber = uniqueSelectedSectionTags.length;

  const handleTagClick = (tag: ListingTag, isSelected: boolean) => {
    if (isSelected) { // remove tag, it was unselected after click
      setSelectedTags((currentTags: SearchAttribute[]) => currentTags.filter(
        (selectedTag) => !(selectedTag.attributeType === sectionDataType && selectedTag.attributeId === tag.value)
      ));
    } else { // add tag, it was selected after click
      // we had a bug, which leads to attribute duplicates, so we need to make the result list unique
      setSelectedTags((currentTags: SearchAttribute[]) => Array.from(
        [
          ...currentTags,
          {...tag, attributeType: sectionDataType, attributeId: tag.value, value: "true"},
        ].reduce(
          (tagsMap, tag) => tagsMap.set(
            tag.attributeType + tag.attributeId, // the unique identifier of the tag
            tag
          ),
          new Map<string, typeof selectedSectionTags[number]>()
        ).values()
      ));
    }

    if (!hasUnsavedData && setHasUnsavedData) {
      setHasUnsavedData(true);
    }
  };

  return (
    <TagSection
      sectionTitle={sectionTitle}
      tags={tags}
      isExpandable={false}
      selectedTagsCount={selectedTagsNumber}
      checkIsSelectedTag={(tag: SearchAttribute) =>
        !!selectedTags.find(
          (selectedTag) =>
            selectedTag.attributeType === sectionDataType && tag.value === selectedTag.attributeId,
        )
      }
      handleTagClick={handleTagClick}
      sectionHeaderStyles={styles.sectionHeader}
      tagSectionStyles={styles.tagSection}
      sectionTitleStyles={styles.sectionTitle}
    />
  );
};

export default TagsSectionItem;
