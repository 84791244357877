import { useMemo } from "react";
import { Log, SearchLogic } from "blace-frontend-library";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { ListingItemEditableData } from "@/src/component/view/ListingManagement/ListingManagement";
import { InputList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/DetailsForm/DetailsForm";
import { FormLogic } from "@/src/model";
import { BlaceV2Type } from "@/src/type";

interface UseDetailsFormProps {
  listingItemData?: ListingItemEditableData;
  listingItemSaveHandler?: (data: ListingItemEditableData) => Promise<Record<string, string>>;
  dataType?: BlaceV2Type.SearchType.SearchDataType;
}

interface DetailsFormType {
  [InputList.ListingName]: string;
  [InputList.ListingDescription]: string;
  [InputList.ListingLocation]: BlaceV2Type.SearchType.SearchLocation | null;
  [InputList.FloorsNumber]: string;
  [InputList.SquareFootage]: string;
  [InputList.CeilingHeight]: string;
  [InputList.StandingCapacity]: string;
  [InputList.SeatedCapacity]: string;
  [InputList.TheaterCapacity]: string;
  [InputList.Files]: BlaceV2Type.SearchType.SearchFile[];
  [InputList.ListingThingsYouShouldKnow]: string;
  [InputList.Regions]: string[];
}

export function useDetailsForm({
  listingItemData,
  listingItemSaveHandler,
  dataType,
}: UseDetailsFormProps): {
  formik: FormikProps<DetailsFormType>;
} {
  const defaultTitle = listingItemData?.title ?? "";
  const defaultDescription = listingItemData?.description ?? "";
  const defaultAddress = listingItemData?.locations?.length ? listingItemData?.locations[0] : null;
  const defaultStandingCapacity = listingItemData?.capacity?.standing?.toString() ?? "";
  const defaultSeatedCapacity = listingItemData?.capacity?.seated?.toString() ?? "";
  const defaultTheaterCapacity = listingItemData?.capacity?.theater?.toString() ?? "";
  const defaultCeilingHeight = listingItemData?.dimensions?.ceilingHeight?.toString() ?? "";
  const defaultFloorsNumber =
    SearchLogic.legacyFloorsToV2Number(listingItemData?.dimensions?.numberOfFloors?.toString()) ??
    "";
  const defaultSqFootage = listingItemData?.dimensions?.sqFootage?.toString() ?? "";
  const defaultFiles = listingItemData?.files ?? [];
  const defaultThingsYouShouldKnow = listingItemData?.facts?.thingsYouShouldKnow ?? "";
  const defaultRegions = listingItemData?.regions ?? [];

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [InputList.ListingName]: Yup.string()
        .min(2, "Please enter a name with a character count between 2 and 512")
        .max(512, "Please enter a name with a character count between 2 and 512"),

      [InputList.ListingDescription]: Yup.string()
        .min(50, "Please enter a description with a character count between 50 and 3,000")
        .max(7999, "Please enter a description with a character count between 50 and 7,999"),

      [InputList.FloorsNumber]: Yup.number()
        .notRequired()
        .typeError("Please enter a number")
        .min(1, "Please enter a floor number between 1 and 50")
        .max(50, "Please enter a floor number between 1 and 50"),

      [InputList.SquareFootage]: Yup.number()
        .notRequired()
        .typeError("Please enter a number")
        .min(0, "Please enter sq. footage between 1 and 500,000")
        .max(500000, "Please enter sq. footage between 1 and 500,000"),

      [InputList.CeilingHeight]: Yup.number()
        .notRequired()
        .typeError("Please enter a number")
        .min(0, "Please enter ceiling height between 4 and 100")
        .max(100, "Please enter ceiling height between 4 and 100"),

      [InputList.StandingCapacity]: Yup.number()
        .typeError("Please enter a number")
        .min(1, "Please enter a guest count between 1 and 300,000")
        .max(300000, "Please enter a guest count between 1 and 300,000"),

      [InputList.SeatedCapacity]: Yup.number()
        .notRequired()
        .typeError("Please enter a number")
        .min(1, "Please enter a guest count between 1 and 300,000")
        .max(300000, "Please enter a guest count between 1 and 300,000"),

      [InputList.TheaterCapacity]: Yup.number()
        .notRequired()
        .typeError("Please enter a number")
        .min(1, "Please enter a guest count between 1 and 300,000")
        .max(300000, "Please enter a guest count between 1 and 300,000"),

      [InputList.ListingThingsYouShouldKnow]: Yup.string()
        .min(5, "Please enter a text with a character count between 5 and 3,000")
        .max(3000, "Please enter a text with a character count between 5 and 3,000"),
      [InputList.Regions]: Yup.array().of(Yup.string()),
    });
  }, []);

  const formik = useFormik<DetailsFormType>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      [InputList.ListingName]: defaultTitle,
      [InputList.ListingDescription]: defaultDescription,
      [InputList.ListingLocation]: defaultAddress,
      [InputList.FloorsNumber]: defaultFloorsNumber,
      [InputList.SquareFootage]: defaultSqFootage,
      [InputList.CeilingHeight]: defaultCeilingHeight,
      [InputList.StandingCapacity]: defaultStandingCapacity,
      [InputList.SeatedCapacity]: defaultSeatedCapacity,
      [InputList.TheaterCapacity]: defaultTheaterCapacity,
      [InputList.Files]: defaultFiles,
      [InputList.ListingThingsYouShouldKnow]: defaultThingsYouShouldKnow,
      [InputList.Regions]: defaultRegions,
    },
    validationSchema,
    onSubmit: async (values, { setFieldError, setFieldTouched }) => {
      let formValuesData: ListingItemEditableData = {
        title: Boolean(values[InputList.ListingName]) ? values[InputList.ListingName] : null,
        description: Boolean(values[InputList.ListingDescription])
          ? values[InputList.ListingDescription]
          : null,

        files: values[InputList.Files],
      };

      if (dataType === BlaceV2Type.SearchDataTypes.Venue) {
        formValuesData = {
          ...formValuesData,
          dimensions: {
            ceilingHeight: Boolean(values[InputList.CeilingHeight])
              ? +values[InputList.CeilingHeight]
              : null,
            sqFootage: Boolean(values[InputList.SquareFootage])
              ? +values[InputList.SquareFootage]
              : null,
            numberOfFloors: Boolean(values[InputList.FloorsNumber])
              ? +values[InputList.FloorsNumber]
              : null,
          },
          capacity: {
            seated: Boolean(values[InputList.SeatedCapacity])
              ? +values[InputList.SeatedCapacity]
              : null,
            standing: Boolean(values[InputList.StandingCapacity])
              ? +values[InputList.StandingCapacity]
              : null,
            theater: Boolean(values[InputList.TheaterCapacity])
              ? +values[InputList.TheaterCapacity]
              : null,
          },
          locations: values[InputList.ListingLocation] ? [values[InputList.ListingLocation]] : [],
          facts: {
            thingsYouShouldKnow: Boolean(values[InputList.ListingThingsYouShouldKnow])
              ? values[InputList.ListingThingsYouShouldKnow]
              : null,
          },
        };
      } else {
        formValuesData = {
          ...formValuesData,
          regions: Boolean(values[InputList.Regions]) ? values[InputList.Regions] : [],
        };
      }
      // handle backend validation
      const unknownFields: string[] = [];
      const errors = listingItemSaveHandler ? await listingItemSaveHandler(formValuesData) : {};

      FormLogic.handleServerErrors(errors, values, setFieldError, unknownFields);

      // handle a case when backend returned non known fields
      if (Boolean(unknownFields.length)) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "useDetailsForm.tsx",
          "onSubmitError",
          [unknownFields],
          "Unknown fields were returned from the server with errors.",
        );

        // we need to make the form dirty again to unblock Save button
        setFieldTouched(InputList.ListingName, true, false);
      }
    },
  });

  return {
    formik,
  };
}
