import React, { useRef } from "react";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { ListingCategories } from "@/src/const/listingManagament";
import { FormRef } from "@/src/type/app";
import { PricingForm } from "./components/PricingForm";

function PriceContent() {
  const formRef = useRef<FormRef>(null);

  const onSavePricingFormData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <CategoryContentWrapper
      category={ListingCategories.Pricing}
      onSaveFormData={onSavePricingFormData}
    >
      <PricingForm ref={formRef} onSavePricingFormData={onSavePricingFormData} />
    </CategoryContentWrapper>
  );
}

export default PriceContent;
